import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { ErrorText, FormSubmitButtonContainer, InputRow } from "../styled";
import { AutofillContext } from "../contexts/AutofillContext";
import { StateContext } from "../contexts/StateContext";
import { BusinessContext } from "../contexts/BusinessContext";

const bankFieldRows = [
  [
    {
      key: "firstName",
      label: "First Name",
      required: true,
    },
    {
      key: "middleName",
      label: "Middle Name",
    },
    {
      key: "lastName",
      label: "Last Name",
      required: true,
    },
  ],
  [
    {
      key: "ssn",
      label: "SSN",
      required: true,
      type: "password",
    },
    {
      key: "phone",
      label: "Phone Number",
      required: true,
      type: "tel",
    },
    {
      key: "birthday",
      label: "Birthday",
      required: true,
      type: "date",
    },
  ],
  [
    {
      key: "email",
      label: "Email",
      required: true,
      type: "email",
    },
    {
      key: "pw",
      label: "Password",
      required: true,
      type: "password",
    },
    {
      key: "pwVerify",
      label: "Confirm Password",
      required: true,
      type: "password",
    },
  ],
];

const marketplaceFieldRows = [
  [
    {
      key: "firstName",
      label: "First Name",
      required: true,
    },
    {
      key: "lastName",
      label: "Last Name",
      required: true,
    },
    {
      key: "phone",
      label: "Phone Number",
      required: true,
      type: "tel",
    },
  ],
  [
    {
      key: "email",
      label: "Email",
      required: true,
      type: "email",
    },
    {
      key: "pw",
      label: "Password",
      required: true,
      type: "password",
    },
    {
      key: "pwVerify",
      label: "Confirm Password",
      required: true,
      type: "password",
    },
  ],
];

export const PersonalInformationFormBody = ({ setPage }) => {
  const { state, setState } = useContext(StateContext);
  const { setCallback, clearCallback } = useContext(AutofillContext);
  const [hasError, setHasError] = useState(false);
  const { id } = useContext(BusinessContext);

  const onChange = (field) => (event) => {
    event.persist();
    setState({ ...state, [field]: event.target.value });
  };

  useEffect(() => {
    setCallback(() =>
      setState((s) => ({
        ...s,
        firstName: "John",
        middleName: "Allen",
        lastName: "Doe",
        ssn: "123456789",
        birthday: "1980-02-24",
        phone: "5551239876",
        email: "johndoe1@email.com",
        pw: "password123",
        pwVerify: "password123",
      }))
    );

    return () => clearCallback();
  }, [setCallback, clearCallback, setState, id]);

  const valid = useMemo(
    () =>
      (id === "sb" ? bankFieldRows : marketplaceFieldRows)
        .flat()
        .filter(({ required }) => required)
        .reduce((a, c) => a && state[c.key] && state[c.key].length, true),
    [state, id]
  );

  const onNext = useCallback(() => {
    if (!valid) {
      setHasError(true);
    } else {
      setHasError(false);
      setPage(id === "sb" ? "address" : "verify");
    }
  }, [setPage, id, valid]);

  return (
    <Fragment>
      <Typography variant="h4">Personal Information</Typography>
      {id === "sb"
        ? bankFieldRows.map((fields) => (
            <InputRow>
              {fields.map((field) => (
                <TextField
                  {...field}
                  fullWidth
                  value={state[field.key]}
                  onChange={onChange(field.key)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ))}
            </InputRow>
          ))
        : marketplaceFieldRows.map((fields) => (
            <InputRow>
              {fields.map((field) => (
                <TextField
                  {...field}
                  fullWidth
                  value={state[field.key]}
                  onChange={onChange(field.key)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ))}
            </InputRow>
          ))}
      <FormSubmitButtonContainer>
        <div style={{ flexGrow: 1 }}></div>
        {hasError ? (
          <ErrorText>Please fill all required fields.</ErrorText>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onNext}
          disabled={!valid}
        >
          Next
        </Button>
      </FormSubmitButtonContainer>
    </Fragment>
  );
};
