import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Card } from "@material-ui/core";
import { PersonalInformationFormBody } from "./PersonalInformationFormBody";
import { AddressInformationFormBody } from "./AddressInformationFormBody";
import { BankInformationFormBody } from "./BankInformationFormBody";
import { ConnectAccountsFormBody } from "./ConnectAccountsFormBody";
import { BusinessContext } from "../contexts/BusinessContext";
import { StateContext, StateContextProvider } from "../contexts/StateContext";
import { VerifyEmailFormBody } from "./VerifyEmailFormBody";

const CardContainer = styled(Card)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  width: 60rem;
  > * {
    margin-bottom: 1rem;
  }

  > hr {
    width: 100%;
  }
`;

const LogoImg = styled.img`
  height: 5rem;
  flex-grow: 0;
`;

const ProgressLineContainer = styled.div`
  height: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  background-color: #cccccc;
  position: relative;
  margin-bottom: 2rem;
`;

const ProgressLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 1rem;
  border-radius: 0.5rem;
  width: ${({ progressPercent }) => progressPercent}%;
  background-color: ${({ color }) => color};
  margin-bottom: 2rem;
`;

const RegisterCardBase = () => {
  const { wordmark, color, id } = useContext(BusinessContext);
  const { setState } = useContext(StateContext);
  const [page, setPage] = useState("personal");

  const progressPercent = useMemo(() => {
    switch (page) {
      case "personal":
        return id === "sb" ? 25 : 40;
      case "address":
        return 50;
      case "accounts":
      case "add-account":
        return 75;
      case "verify":
        return 95;
      default:
        return 100;
    }
  }, [page, id]);

  useEffect(() => {
    setState({
      firstName: "",
      middleName: "",
      lastName: "",
      ssn: "",
      phone: "",
      email: "",
      birthday: "",
      pw: "",
      pwVerify: "",
      street1: "",
      street2: "",
      city: "",
      region: "",
      country: "",
      postal_code: "",
      accounts: [],
    });
  }, [setState]);

  return (
    <CardContainer>
      <LogoImg src={wordmark} />
      <ProgressLineContainer>
        <ProgressLine
          progressPercent={progressPercent}
          color={color}
        ></ProgressLine>
      </ProgressLineContainer>

      {page === "personal" ? (
        <PersonalInformationFormBody setPage={setPage} />
      ) : null}

      {page === "address" ? (
        <AddressInformationFormBody setPage={setPage} />
      ) : null}

      {page === "accounts" ? (
        <ConnectAccountsFormBody setPage={setPage} />
      ) : null}

      {page === "verify" ? <VerifyEmailFormBody /> : null}

      {page === "add-account" ? (
        <BankInformationFormBody setPage={setPage} />
      ) : null}
    </CardContainer>
  );
};

export const RegisterCard = ({ children, ...props }) => {
  return (
    <StateContextProvider>
      <RegisterCardBase {...props}>{children}</RegisterCardBase>
    </StateContextProvider>
  );
};
